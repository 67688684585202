.promo__wrapper-gender {
  width: 100%;
  max-width: 392px;
  background-color: #fff;
  width: 100%;
  margin: 176px auto 0;
  border-radius: 8px;
}
.gender__form-content {
  padding: 56px 30px;
}
.custom-select {
  select {
    background: #ffffff;
    border: 1px solid #999ead;
    outline: none;
    box-sizing: border-box;
    border-radius: 14px;
    height: 52px;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    background: url(../../images/arrow.svg);
    background-repeat: no-repeat;
    background-size: 25px 8px;
    background-position: right 4% bottom 50%;
    font-size: 17px;
    color: #1a1f24;
    text-indent: 15px;
  }
  &-me {
    display: flex;
    flex-direction: column;
  }
}
.button__gender {
  margin-top: 24px;
}

.error {
  margin-top: 8px;
  font-size: 13px;
  line-height: 16px;
  color: #ff044f;
  text-align: center;
}

@media (max-width: 421px) {
  .gender__form-content {
    padding: 24px 16px;
  }
  .promo__wrapper-gender {
    margin: 152px auto 0;
  }
}
@media (max-width: 376px) {
  .custom-select select {
    font-size: 16px;
  }
}
@media (max-width: 321px) {
  .promo__wrapper-gender {
    margin: 100px auto 0;
  }
  .custom-select select {
    font-size: 13px;
  }
}
