.button__global {
  width: 100%;
  max-width: 100%;
  border: none;
  background: #7d0303;
  border-radius: 14px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  font-size: 17px;
  line-height: 16px;
  padding: 16px 0;
  display: block;
  margin: auto;
  height: 48px;
  color: #ffff;
  &:hover {
    background: #ad4141;
  }
}
