.name__form-wrapper {
  background-color: #ffff;
  border-radius: 8px;
  width: 100%;
  max-width: 392px;
  margin: 176px auto 0;
}
.form__name {
  padding: 56px 48px;
}

.input__name {
  display: block;
  margin: auto;
}

.button__name {
  display: block;
  margin: 24px auto 0;
}

.errorName {
  font-size: 13px;
  line-height: 16px;
  color: #ff044f;
  text-align: center;
  margin-top: 8px;
}
@media (max-width: 421px) {
  .form__name {
    padding: 24px 16px;
  }
  .name__form-wrapper {
    margin: 152px auto 0;
  }
}

@media (max-width: 321px) {
  .name__form-wrapper {
    margin: 100px auto 0;
  }
}
