.error__form-wrapper {
  background-color: #fff;
  width: 100%;
  max-width: 392px;
  margin: 176px auto 0;
  border-radius: 8px;
}

.password__form {
  padding: 56px 48px;
}

.form-group {
  display: block;
  width: 100%;
  max-width: 354px;
  margin: auto;

  margin: 32px auto 0;
  .checkBox {
    display: none;

    &:checked + label::after {
      background: #290fba;
      border-radius: 2px;
    }

    & + label {
      z-index: 3;
      cursor: pointer;
      padding-left: 33px;
      display: block;
      position: relative;
      height: auto;
      font-size: 17px;
      color: #070f1b;
      line-height: 24px;
      span{
        color:  #111212;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        display: inline-block;
        > a{
          color: #0047BF;
          text-decoration: underline;
        }
      }

      &::before {
        z-index: 2;
        top: 0px;
        left: 2px;
        width: 24px;
        height: 24px;
        display: block;
        content: "";
        position: absolute;
        border: 1px solid #290fba;
        border-radius: 2px;
      }

      &::after {
        z-index: 2;
        top: 2px;
        left: 4px;
        width: 20px;
        height: 20px;
        background: #fff;
        display: block;
        content: "";
        position: absolute;
      }
    }
  }
}

.input__password {
  display: block;
  margin: auto;
}
.button__password {
  display: block;
  margin: 32px auto 0;
}

.input__password-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  .logo__eye {
    position: absolute;
    border: none;
    background: none;
    right: 15px;
    cursor: pointer;
    z-index: 99999;
  }
}

.errorPassword {
  color: #ff044f;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  margin-top: 8px;
}
.spin-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1010;
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #282828;
    font-weight: bold;
    transform: translate(-50%, -50%);
  }
}
@media (max-width: 421px) {
  .password__form {
    padding: 24px 16px;
  }
  .error__form-wrapper {
    margin: 152px auto 0;
  }
}
@media (max-width: 376px) {
  .error__form-wrapper {
    margin: 100px auto 0;
  }
}
@media (max-width: 321px) {
  .form-group .checkBox + label {
    font-size: 16px;
  }
  .input__password-wrapper .logo__eye {
    right: 0;
  }
  .error__form-wrapper {
    margin: 65px auto 0;
  }
}
