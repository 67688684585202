.promo__wrapper-birthday {
  width: 100%;
  max-width: 424px;
  background-color: #fff;
  width: 100%;
  max-width: 424px;
  margin: 176px auto 0;
  border-radius: 8px;
}

.form__date {
  width: 100%;
  padding: 56px 48px;
  margin: auto;
}
.date__wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: auto;
  max-width: 328px;
  padding: 0 15px 0 15px;
  &-select {
    &:not(:last-child) {
      margin-right: 7px;
    }
    select {
      font-size: 16px;
      line-height: 120%;
      color: #565b6b;
      border: 1px solid #999ead;
      border-radius: 14px;
      cursor: pointer;
      outline: none;
      width: 90px;
      padding-left: 8px;
      height: 48px;
      -webkit-appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      appearance: none;
      background-image: url(../../images/arrow.svg);
      background-size: 12px;
      background-repeat: no-repeat;
      background-position: 89%;
      background-color: transparent;
      &.active {
        color: #1a1f24;
      }
    }
  }
}
.button__birthday {
  margin-top: 24px;
}

.errorDate {
  color: #ff044f;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  margin-top: 8px;
}
.birthday_confirm{
  color: #000;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-top: 24px;
}

@media (max-width: 420px) {
  .promo__wrapper-birthday {
    margin: 152px auto 0;
  }
  .form__date {
    padding: 24px 16px;
  }
  .date__wrapper {
    padding: 0;
    justify-content: space-between;
  }
}
@media (max-width: 321px) {
  .promo__wrapper-birthday {
    margin: 100px auto 0;
  }
  .date__wrapper-select select {
    width: 81px;
    padding-left: 5px;
  }
}
