.footer-menu {
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  position: relative;

  @media(max-width: 960px) {
    flex-wrap: wrap;
  }
  @media(max-width: 663px) {
    flex-wrap: unset;
    flex-direction: column;
  }

  .block {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 16px;
      @media(max-width: 663px) {
        margin: 0 0 16px 0;
      }
    }


    a {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }

    @media(max-width: 945px) {
      &:nth-child(3) {
        margin-top: 16px;
        @media(max-width: 663px) {
          margin-top: unset;
        }
      }
    }

  }


  a {
    color:  #5F5F62;
    text-align: right;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration-line: underline;
    @media (max-width: 321px) {
      font-size: 13px;
    }
  }
}


.cookie__policy-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  position: fixed;
  right: 0;
  left: 0;
  margin: auto;
  bottom: 16px;
  z-index: 10500;
  width: 100%;
  max-width: 1035px;
  box-sizing: border-box;
  background-color: #FFF;
  border-radius: 8px;

  &.animate__fadeOutDown {
    animation: fadeOutDown 1s forwards;
  }

  &.hide {
    display: none;
  }

  > p {
    color: #111212;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-right: 16px;
    text-align: left;
    font-family: "Inter",sans-serif;
    @media(max-width: 430px){
      font-size: 12px;
      line-height: 16px;
    }
  }

  > button {
    width: 100%;
    max-width: 163px;
    height: auto;
    padding: 8px;
    border-radius: 8px;
    background: #111212;
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    border: 1px solid transparent;
    cursor: pointer;
    @media(max-width: 430px){
      font-size: 12px;
      line-height: 14px;
    }

    &:hover {
      border: 1px solid #111212;
      background: rgba(25, 25, 38, 0.12);
      color: #111212;
    }

    &:active {
      border: 1px solid #111212;
      color: #111212;
      background-color: transparent;
    }
  }
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(100px);
  }
}
