@import "reset";
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.content__wrapper {
  background-image: url(../images/bg-convert.png);
  background-repeat: repeat;
  background-position-y: calc(100vh - 102px);
  max-width: 100%;
  display: block;
  height: 100vh;
  -webkit-animation: backgroundScroll 100s linear infinite;
  animation: backgroundScroll 100s linear infinite;
  position: relative;
}
.header {
  background-color: #fff;
  width: 100%;
  height: 56px;
  padding: 11px 0;
  &-logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

#root {
  margin: auto;
  padding: 0 16px;
}

@-webkit-keyframes backgroundScroll {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 5000px 0;
  }
}

@keyframes backgroundScroll {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 5000px 0;
  }
}

@media (max-width: 1680px) {
  @media (max-height: 600px) {
    #root {
      .promo__wrapper,
      .error__form-wrapper {
        margin: 20px auto 0;
      }
      .promo__wrapper-gender,
      .promo__wrapper-birthday,
      .name__form-wrapper,
      .email__form-wrapper {
        margin: 70px auto 0;
      }
    }
  }
}

@media (max-width: 767px) {
  .content__wrapper {
    background-image: url(../images/grid_mobile-min.png);
  }
}
