.email__form-wrapper {
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  max-width: 392px;
  margin: 176px auto 0;
}

.form__email {
  padding: 56px 48px;
}

.button__email {
  margin: 24px auto 0;
}

.errorEmail {
  color: #ff044f;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  margin-top: 8px;
}
.input__wrapper {
  position: relative;
}
.autocomplete__box {
  position: absolute;
  max-width: 420px;
  width: 100%;
  height: 200px;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #fff;
  box-shadow: 0px 13px 24px rgba(0, 0, 0, 0.24);
  border-radius: 0 0 8px 8px;
  padding: 16px 0;
  box-sizing: border-box;

  ul {
    margin: 0;
  }
  ul > li {
    color: #565b6b;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-family: inherit;
    cursor: pointer;
    text-align: left;
    text-indent: 12px;
    padding: 8px 0;
    &:hover {
      background: #565b6b;
      color: #ffff;
    }
  }
}
@media (max-width: 421px) {
  .form__email {
    padding: 24px 16px;
  }
  .email__form-wrapper {
    margin: 152px auto 0;
  }
}
@media (max-width: 321px) {
  .email__form-wrapper {
    margin: 100px auto 0;
  }
}
