.promo__wrapper {
  width: 100%;
  max-width: 392px;
  margin: 70px auto 0;
  display: block;
  background-color: #fff;
  border-radius: 8px;
}
.user__form-top {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  background: #eaab08;
  padding: 8px 0;
  border-radius: 8px 8px 0px 0px;
}
.text__wrapper {
  width: 100%;
  max-width: 296px;
  margin: auto;
}

.user__form-content {
  padding: 24px 48px;
  img {
    margin: 0 auto 24px;
  }
  h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #000000;
    margin-bottom: 8px;
  }
  p {
    font-size: 13px;
    line-height: 120%;
    text-align: center;
    color: #000000;
    max-width: 296px;
  }
}
.button__promo {
  margin-top: 24px;
}
.progress__dots {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px auto 0;
  max-width: 66px;
  justify-content: space-between;
  span {
    background: rgba(146, 172, 194, 0.24);
    border-radius: 3px;
    display: block;
    width: 6px;
    height: 6px;
  }
  .active {
    width: 6px;
    height: 6px;
    background: #290fba;
    border-radius: 3px;
  }
}

@media (max-width: 421px) {
  .user__form-content {
    padding: 24px 16px;
  }
}

@media (max-width: 321px) {
  .promo__wrapper {
    margin: 15px auto 0;
  }
}
