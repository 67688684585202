.input__global {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #999ead;
  border-radius: 14px;
  text-indent: 15px;
  height: 52px;
  padding: 14px 0;
  outline: none;
  &::placeholder {
    font-size: 17px;
    line-height: 24px;
    color: #565B6B;
  }
}
